import { Injectable } from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {AccountInventoryField} from '../model/account-inventory-field';
import {Observable} from 'rxjs';
import {InventoryFieldModel} from '../model/inventory-field.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryMappingService extends AbstractHttpService {
  defaultOptions = {
    headers: this.acceptJsonHeaders,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getInventoryMappingFields(accountId: number): Observable<any> {
    return this.httpClient.get<AccountInventoryField[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/inventory-mapping-fields?accountId=${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  public getInventoryFields(): Observable<any> {
    return this.httpClient.get<InventoryFieldModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/inventory-fields`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  //TODO LC-11464: migrate this logic to auto api
  public getInventoryHeaders(accountId: number): Observable<any> {
    return this.httpClient.get<any[]>(
      `/includes/ajax/mpop-api/inventory/get-inventory-headers/${accountId}/inventory`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
