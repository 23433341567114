import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NavigationService} from '../../../../service/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {InventoryMappingService} from '../../../../service/inventory-mapping.service';

@Component({
  selector: 'auto-inventory-mapping',
  templateUrl: './inventory-mapping.component.html',
  styleUrl: './inventory-mapping.component.scss'
})
export class InventoryMappingComponent implements OnInit {

  accountId: number;
  settingsSideNav: MenuItem[];
  inventoryMappingForm: FormGroup;
  offerFieldOptions = [];
  inventoryHeaders = [];
  inventoryFields = [];
  separators = ['|', ',', ' '];
  inventoryMappingFields = [];
  isFieldLoaded: boolean = false;

  constructor(private navigationService: NavigationService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private inventoryMappingService: InventoryMappingService,
              private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getInventoryFields();
    });
  }

  /**
   * Get inventory mapping fields
   */
  getInventoryMappingFields(accountId: number) {
    this.inventoryMappingService.getInventoryMappingFields(accountId)
      .subscribe({
          next: (inventoryMappingFields) => {
            this.inventoryMappingFields = inventoryMappingFields;
            this.initForm();
            this.isFieldLoaded = true;
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load inventory mapping.'
            });
          }
        }
      );
  }

  /**
   * Get fields
   */
  get fields(): UntypedFormArray {
    return this.inventoryMappingForm.get('fields') as UntypedFormArray;
  }

  /**
   * Initialize form group array
   */
  private initForm() {
    this.inventoryMappingForm = this.formBuilder.group({
      fields: this.formBuilder.array([]),
    });
    this.inventoryMappingFields.forEach(
      (field) => {
        this.fields.push(this.formBuilder.group({
          name: [{value: field.name, disabled: this.isRequiredField(field.name)}],
          inventoryHeader: [this.inventoryHeaders[field.colNum]],
          isMultiValue: [field.isMultiValue],
          multiValueSeparator: [field.multiValueSeparator]
        }));
      }
    )
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'inventory-settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Get inventory fields
   */
  private getInventoryFields() {
    this.inventoryMappingService.getInventoryFields()
      .subscribe({
          next: (inventoryFields) => {
            this.inventoryFields = inventoryFields;
            this.inventoryFields.forEach((field) => {
              this.offerFieldOptions.push(field.name);
            });
            this.offerFieldOptions.sort();
            this.getInventoryHeaders();
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load inventory fields.'
            });
          }
        }
      );
  }

  /**
   * Get inventory headers
   */
  private getInventoryHeaders() {
    this.inventoryMappingService.getInventoryHeaders(this.accountId).subscribe(
      {
        next: (inventoryHeaders) => {
          this.inventoryHeaders = inventoryHeaders;
          this.getInventoryMappingFields(this.accountId);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to load inventory headers.'
          });
        }
      }
    )
  }

  /**
   * Check if field is required
   */
  isRequiredField(name) {
    return this.inventoryFields.find((field) => field.name === name && field.required);
  }
}
