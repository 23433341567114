import {Component, Input, OnInit} from '@angular/core';
import {OfferSetSharingRuleModel} from '../../../../../model/offer-set-sharing-rule.model';

@Component({
  selector: 'auto-shared-offer-set-rules-table',
  templateUrl: './shared-offer-set-rules-table.component.html',
  styleUrl: './shared-offer-set-rules-table.component.scss'
})
export class SharedOfferSetRulesTableComponent implements OnInit {
  @Input() sharedOfferSetsRules: OfferSetSharingRuleModel[] = [];
  @Input() sourceAccounts: any[] = [];
  searchValue: string;

  globalFilterFields = ['ruleName', 'sourceAccount.name'];

  ngOnInit(): void {
  }
}
