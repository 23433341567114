<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">Inventory Settings</h1>
  </p-header>
  <div class="main-content-wrapper p-grid">
    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>
    <p-panel class="p-col ad-types-wrapper">
      <p-header class="mapping-rules-header-wrapper">
        <h2 class="ad-types-header-text">Inventory Mapping</h2>
      </p-header>
      <div class="reset-update-mapping-header">
        <p-button label="Save"
                  styleClass="p-button-success p-button-icon"
                  class="update-mapping-button">
        </p-button>
      </div>
      <div [formGroup]="inventoryMappingForm" *ngIf="isFieldLoaded">
        <div formArrayName="fields">
          <tr class="p-grid p-justify-even form row">
            <th class="p-col-3">Offer Field</th>
            <th class="p-col-3">Column Name</th>
            <th class="p-col-2">Is Multi Value</th>
            <th class="p-col-2">Separator</th>
            <th class="p-col-1"></th>
          </tr>
          <br>
          <div class="p-grid inventory-field p-justify-even form row"
               *ngFor="let field of fields.controls; let idx = index" [formGroupName]="idx">
            <td class="p-col-3 center-aligned-content position-relative custom-dropdown">
              <p-dropdown [options]="offerFieldOptions"
                          formControlName="name"
                          placeholder="Select an Option"
                          pTooltip="Required Field"
                          tooltipPosition="top"
                          [tooltipDisabled]="!isRequiredField(field.get('name').value)"
                          class="dropdown">
              </p-dropdown>
            </td>
            <td class="p-col-3 center-aligned-content position-relative custom-dropdown">
              <p-dropdown
                [options]="inventoryHeaders"
                formControlName="inventoryHeader"
                class="dropdown">
              </p-dropdown>
            </td>
            <td class="p-col-2 center-aligned-content position-relative">
              <p-checkbox binary="true" formControlName="isMultiValue"></p-checkbox>
            </td>
            <td class="p-col-2 center-aligned-content position-relative">
              <p-dropdown [options]="separators" formControlName="multiValueSeparator"
                          *ngIf="field.get('isMultiValue').value"></p-dropdown>
            </td>
            <td class="p-col-1 center-aligned-content position-relative">
              <button *ngIf="!isRequiredField(field.get('name').value)" pButton type="button"
                      class="p-button-danger p-button-icon">
                <i class="pi pi-trash"></i>
              </button>
            </td>
            <p-scrollTop/>
          </div>
          <div class="add-new-field-button">
            <p-button
              label="Add New Field"
              class="new-field-button"
              styleClass="p-button-secondary p-button-icon">
            </p-button>
          </div>
          <div class="reset-update-mapping-header">
              <p-button class="reset-to-default-button" label="Reset To Default"></p-button>
              <p-button label="Save"
                        styleClass="p-button-success p-button-icon"
                        class="update-mapping-button">
              </p-button>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</p-panel>
<auto-footer></auto-footer>
