import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "./service/user.service";
import {map} from "rxjs/operators";
import {promise} from "protractor";

@Injectable({
  providedIn: "root"
})
export class FeatureFlagGuard {

  firstElement = 0;

  constructor(private userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let canAccess: boolean = true;
    const targetFeatureName: string = route.data.featureFlag.featureName;
    let redirectUrl: string = route.data.featureFlag.redirectUrl;
    let accountId: string = route.params.accountId;
    redirectUrl = redirectUrl.replace(':accountId', accountId);

    this.userService.getMpopFeature(targetFeatureName).subscribe(
      (featureFlag) => {
        //Since the endpoint returns a collection and that the feature flag is a unique index, the first element is always the one we want
        if (!featureFlag[this.firstElement].enabled) {
          canAccess = false;
          window.location.href = redirectUrl;
        }
      }
    )

    return canAccess;
  }

  /**
   * Checks if a given feature is active, redirects to a given URL if not
   * @param featureName
   * @param redirectUrl
   */
  checkFeatureAccess(featureName, redirectUrl) {
    this.userService.getMpopFeature(featureName).subscribe(
      (featureFlag) => {
        if (!featureFlag[this.firstElement].enabled) {
          window.location.href = redirectUrl;
        }
      })
  }

  /**
   * Boolean function to check if given feature is on
   * @param featureName
   */
  isFeatureOn(featureName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let result = false;
      this.userService.getMpopFeature(featureName).subscribe(
        (featureFlag) => {
          if (featureFlag[this.firstElement].enabled) {
            result = true;
          }
          resolve(result);
        })
    });
  }
}
