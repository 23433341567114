import {Injectable} from '@angular/core';
import {AbstractHttpService} from './abstract-http-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {OfferSetSharingRuleModel} from '../model/offer-set-sharing-rule.model';

@Injectable({
  providedIn: 'root'
})
export class SharedOfferSetRuleService extends AbstractHttpService {

  getOptions = {
    headers: this.acceptJsonHeaders,
    withCredentials: true
  };

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  getSharedOfferSetRules(): Observable<OfferSetSharingRuleModel[]> {
    return this.httpClient.get<OfferSetSharingRuleModel[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/offer-set-sharing-rules`,
      this.getOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  //TODO add remaining CRUD operations
}
