import {Inject, Injectable} from '@angular/core';
import {UserInterface} from '../model/user.interface';
import {NavConfigInterface} from '../model/nav-config.interface';
import {MenuItem} from 'primeng/api';
import {DOCUMENT} from '@angular/common';
import {UserService} from './user.service';
import {AccountService} from './account.service';
import {HttpClient} from '@angular/common/http';
import {AccountInterface} from '../model/account.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  documentHostname: string;
  documentProtocol: string;
  urlShortcodeMap = {
    '[ACCOUNT_ID]': 'accountId',
    '[ACCOUNT_WEBSITE_URL]': 'accountWebsiteUrl'
  };

  mpopFeatureFlags = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private accountService: AccountService,
    private http: HttpClient
  ) {
    this.documentHostname = this.document.location.hostname;
    this.documentProtocol = this.document.location.protocol;
  }

  public async getMenuItems(accountId, configurationFilename: string, account?: AccountInterface) {
    const configurationPath = `assets/configurations/menus/${configurationFilename}`;
    const navConfig = await this.http.get<NavConfigInterface>(configurationPath).toPromise();
    // TODO: we don't really need the user obj. here, we just need an accountId. Refactor so user is only referenced
    // TODO: in userService.userHasAccessKeys method.
    const user = await this.userService.getUser().toPromise();
    if (!account && accountId) {
      account = await this.accountService.getAccount(accountId).toPromise();
    }
    if (accountId) {
      user.accountId = accountId;
      user.accountWebsiteUrl = account.websiteUrl;
    }

    let featureFlags = await this.userService.getAllMpopFeatures().toPromise();
    featureFlags.forEach((feature) => {
      this.mpopFeatureFlags[feature.name] = feature.enabled;
    });

    return this.buildMenuRecursive(user, navConfig.items);
  }

  private buildMenuRecursive(user, navConfigItems): any[] {
    if (navConfigItems) {
      const completedMenuItems = [];
      for (const currentNavConfigItem of navConfigItems) {
        if (currentNavConfigItem.items && currentNavConfigItem.items.length > 0) {
          const subItems = this.buildMenuRecursive(user, currentNavConfigItem.items);
          const parentMenuItem = this.buildMenuItem(user, currentNavConfigItem);
          if (parentMenuItem) {
            parentMenuItem.items = subItems;
          }
          if (this.menuItemIsValid(parentMenuItem)) {
            completedMenuItems.push(parentMenuItem);
          }
        } else {
          const subMenuItem = this.buildMenuItem(user, currentNavConfigItem);
          if (this.menuItemIsValid(subMenuItem)) {
            completedMenuItems.push(subMenuItem);
          }
        }
      }

      return completedMenuItems;
    }
  }

  private buildMenuItem(user: UserInterface, navItemConfig: NavConfigInterface) {
    let menuItem = null;
    let hasFeatureFlagOn = true;
    if (navItemConfig.featureFlag) {
      hasFeatureFlagOn = this.mpopFeatureFlags[navItemConfig.featureFlag];
    }
    if (this.userService.userHasAccessKeys(user, user.accountId, navItemConfig.userAccessKeys) && hasFeatureFlagOn) {
      menuItem = {
        id: navItemConfig.id,
        label: navItemConfig.label,
        target: navItemConfig.target,
        routerLink: null,
        url: this.buildUrl(user, navItemConfig.url),
        icon: navItemConfig.icon,
        routerLinkActiveOptions: {
          exact: true
        }
      };

      if ('routerLink' in navItemConfig && navItemConfig.routerLink !== '') {
        menuItem.url = null;
        menuItem.routerLink = [
          navItemConfig.routerLink,
          user.accountId
        ];
      }
    }

    return menuItem;
  }

  private menuItemIsValid(menuItem: MenuItem): boolean {
    return menuItem !== null && ((this.menuItemHasLink(menuItem)) || this.menuItemHasSubItems(menuItem));
  }

  private menuItemHasLink(menuItem: MenuItem): boolean {
    return menuItem.url || menuItem.routerLink;
  }

  private menuItemHasSubItems(menuItem: MenuItem) {
    return 'items' in menuItem && menuItem.items.length > 0;
  }

  private buildUrl(user: UserInterface, urlTemplate: string): string {
    let result = null;
    if (urlTemplate) {
      result = this.replaceUrlShortcode(user, urlTemplate);
      if (urlTemplate !== '[ACCOUNT_WEBSITE_URL]') {
        result = `${this.documentProtocol}//${this.documentHostname}${result}`;
      }
    }

    return result;
  }

  private replaceUrlShortcode(user: UserInterface, urlTemplate: string): string {
    Object.keys(this.urlShortcodeMap).forEach((shortcode) => {
      if (urlTemplate.includes(shortcode) && user[this.urlShortcodeMap[shortcode]]) {
        const shortCodeExpression = RegExp('(\\' + shortcode + ')', 'g');
        urlTemplate = urlTemplate.replace(shortCodeExpression, user[this.urlShortcodeMap[shortcode]]);
      }
    });

    return urlTemplate;
  }
}
